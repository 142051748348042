import * as React from 'react';
import { useState, useEffect } from 'react';
import { Dialog, DialogContent, DialogContentText, DialogTitle, Box } from '@mui/material';
import { Button, Typography, TextField, IconButton, Switch } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import FormControlLabel from '@mui/material/FormControlLabel';

const productItemTypes = [
    {
        value: 'HANDYDRAW',
        label: 'HANDYDRAW',
    },
    {
        value: 'HANDYDRAWPHOTOSURVEY',
        label: 'HANDYDRAWPHOTOSURVEY',
    },
    {
        value: 'HANDYDRAWFASTREALITY',
        label: 'HANDYDRAWFASTREALITY',
    },
    {
        value: 'HANDYDRAWPILOT',
        label: 'HANDYDRAWPILOT',
    },
    {
        value: 'HANDYDRAWCRIME',
        label: 'HANDYDRAWCRIME',
    },
    {
        value: 'HANDYDRAWCRIMEPILOT',
        label: 'HANDYDRAWCRIMEPILOT',
    },
    {
        value: 'HANDYCAPTURE',
        label: 'HANDYCAPTURE',
    },
    {
	value: 'HANDYRENDER',
	label: 'HANDYRENDER',
    },
    {
	value: 'HANDYRENDER_HANDYDRAW',
	label: 'HANDYRENDER_HANDYDRAW',
    },
    {
	value: 'HANDYRENDER_HANDYDRAWIMAGE',
	label: 'HANDYRENDER_HANDYDRAWIMAGE',
    },
    {
	value: 'HANDYRENDER_HANDYDRAWPILOT',
	label: 'HANDYRENDER_HANDYDRAWPILOT',
    },
    {
        value: '',
        label: '清 空',
    },
];

const ModifyDeviceDialog = ({
    alertstate,
    productItemType,
    setProductItemType,
    deviceLocked,
    setDeviceLocked,
    dealerName,
    setDealerName,
    remarkInfo,
    setRemarkInfo,
    handlechange,
    handleCancel,
    deviceInfoValue,
}) => {

    return <Dialog
        onClose={handlechange}
        open={alertstate}
        maxWidth='xl'
        sx={{ '& .MuiDialog-paper': { width: '70vw', minHeight: '62vh' } }}
    >
        <DialogTitle textAlign='center' sx={{ mt: 5 }}>修 改 设 备 信 息</DialogTitle>
        <DialogContent sx={{ ml: 5, mr: 5 }}>
            {/* <DialogContentText>{alertinfo}</DialogContentText> */}
            <Box display='flex' justifyContent='space-between' sx={{ mt: 3 }}>
                <TextField label="UDID" sx={{ width: "36%" }} size="small" disabled={true} defaultValue={deviceInfoValue.device_udid} />
                {/* <TextField label="UDID" sx={{ width: "36%" }} size="small" name={udidValue}/> */}
                <TextField label="产品类型" sx={{ width: "16%" }} size="small" disabled={true} defaultValue={deviceInfoValue.product_type} />
                <TextField label="设备序列号" sx={{ width: "16%" }} size="small" disabled={true} defaultValue={deviceInfoValue.device_serial} />
                <TextField label="设备型号" sx={{ width: "10%" }} size="small" disabled={true} defaultValue={deviceInfoValue.device_type} />
                <TextField label="设备版本" sx={{ width: "10%" }} size="small" disabled={true} defaultValue={deviceInfoValue.device_version} />
            </Box>
            <Box display='flex' justifyContent='space-between' sx={{ mt: 5 }}>
                <TextField label="创建时间" sx={{ width: "20%" }} size="small" disabled={true}
                    defaultValue={new Date(deviceInfoValue.create_time).toLocaleString()} />
                <TextField label="激活时间" sx={{ width: "20%" }} size="small" disabled={true}
                    defaultValue={deviceInfoValue.encryption_time ? new Date(deviceInfoValue.encryption_time).toLocaleString() : deviceInfoValue.encryption_time} />

                <TextField label="次数" sx={{ width: "8%" }} size="small" disabled={true} defaultValue={deviceInfoValue.operation_count} />

                <TextField label="软件型号" select sx={{ width: "30%" }} size="small" value={productItemType}
                    onChange={(event) => {
                        console.log(event.target.value);
                        deviceInfoValue.product_item_type = event.target.value;
                        setProductItemType(event.target.value)
                    }}>
                    {productItemTypes.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </TextField>

                <FormControlLabel
                    value="start"
                    control={<Switch color="primary" checked={deviceLocked} onChange={(event) => {
                        setDeviceLocked(event.target.checked);
                        //deviceInfoValue.device_locked = event.target.checked;
                    }} />}
                    label="是否锁定"
                    labelPlacement="start"
                />
            </Box>
            <Box display='flex' justifyContent='space-between' sx={{ mt: 5 }}>
                <TextField label="软件序列号" sx={{ width: '30%' }} disabled={true} size="small" defaultValue={deviceInfoValue.product_serial} />
                <TextField label="软件激活码" sx={{ width: '30%' }} disabled={true} size="small" defaultValue={deviceInfoValue.product_key} />
                <TextField label="代理商" sx={{ width: '30%' }} size="small" defaultValue={dealerName} onChange={(event) => {
                    //setDealerName(event.target.value);
                    deviceInfoValue.dealer_name = event.target.value;
                    setDealerName(event.target.value);
                }} />
            </Box>

            <Box display='flex' sx={{ mt: 5 }} justifyContent='space-between'>
                <TextField label="备注" multiline rows={4} sx={{ width: '100%', height: '100%' }} size="small"
                    defaultValue={remarkInfo} onChange={(event) => {
                        //deviceInfoValue.remark_info = event.target.value;
                        setRemarkInfo(event.target.value);
                    }} />
            </Box>
        </DialogContent>
        <Box sx={{ display: 'flex', mb: 5, justifyContent: 'space-evenly' }}>
            <Button onClick={(event) => handlechange(deviceInfoValue)} variant="contained" autoFocus={true} sx={{ width: '20%' }}>确定</Button>
            <Button onClick={handleCancel} variant="contained" sx={{ width: '20%' }} >取消</Button>
        </Box>
    </Dialog>
}

export default ModifyDeviceDialog;
